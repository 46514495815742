import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Button from '../components/button';
import AwesomeSlider from 'react-awesome-slider';
import './../scss/index.scss';
import { BannerInterface } from '../models/Banner';
import Banner from '../components/banner';
import Slider from '../components/slider';
import Title from '../components/title';
import Fade from 'react-reveal/Fade';
import AccordionSection from '../components/accordion';
import KnowMore from '../components/know-more';
import Opinions from '../components/opinions';
import OurBlog from '../components/our-blog';
import { connect } from 'react-redux';
import { handleShowModalQuote } from '../redux/actions';
import { ACTION_TYPES } from '../constants/constants';

export interface IndexProps {
  dispatch: any;
}

const BANNERS: BannerInterface[] = [
  {
    image: '/images/Proyectat-05.png',
    title: 'Proyectat es disruptiva y sostenible',
    subtitle:
      'Somos la agencia máster de mayor crecimiento y sostenibilidad en el tiempo de la región.',
    hasButton: false,
  },
  {
    image: '/images/Proyectat-07.png',
    title: 'Una compañía para crecer juntos',
    subtitle: 'Comienza a construir tu negocio de la mano de los mejores',
    hasButton: false,
  },
  {
    image: '/images/Proyectat-01.png',
    title: 'Un seguro de vida como tú',
    subtitle: 'Para que lo disfrutes en lo que quieras',
    buttonText: 'Quiero saber más',
    buttonLink: '/vida-y-acumulacion',
    mobileBackgroundColor: '#fff',
    mobilePaddingBottom: '4rem',
    hasButton: true,
    dark: true,
  },
  {
    image: '/images/Proyectat-03.png',
    title: 'Un seguro de vida como tú',
    subtitle:
      'Para que lo disfrutes en lo que quieras. Además de contar con todos los beneficios de un seguro de vida, puedes acumular capital para tus proyectos y metas.',
    buttonText: 'Quiero saber más',
    buttonLink: ACTION_TYPES.SHOW_MODAL_QUOTE,
    hasButton: true,
  },
];

const Index: React.FC<IndexProps> = ({ dispatch }) => {
  const showModalQuote = () => dispatch(handleShowModalQuote(true));

  return (
    <div className="index screen__container">
      <SEO title="Inicio" />
      <Layout>
        <div css={{ '@media (max-width: 767px)': { display: 'none' } }}>
          <AwesomeSlider>
            {BANNERS.map(
              (
                {
                  image,
                  title,
                  subtitle,
                  hasButton,
                  buttonText,
                  buttonLink,
                  buttonMarginTop,
                },
                index
              ) => (
                <div key={`banner-${index}`} className="hero">
                  <Banner
                    title={title}
                    subtitle={subtitle}
                    image={image}
                    buttonText={buttonText}
                    buttonLink={buttonLink}
                    buttonMarginTop={buttonMarginTop}
                    hasButton={hasButton}
                  ></Banner>
                </div>
              )
            )}
          </AwesomeSlider>
        </div>
        <div css={{ '@media (min-width: 768px)': { display: 'none' } }}>
          <Banner
            title={BANNERS[0].title}
            subtitle={BANNERS[0].subtitle}
            image={BANNERS[0].image}
            buttonText={BANNERS[0].buttonText}
            hasButton={BANNERS[0].hasButton}
            buttonLink={BANNERS[0].buttonLink}
            buttonMarginTop={BANNERS[0].buttonMarginTop}
            mobileBackgroundColor={BANNERS[0].mobileBackgroundColor}
            mobilePaddingBottom={BANNERS[0].mobilePaddingBottom}
            dark={BANNERS[0].dark}
          ></Banner>
        </div>
        <div className="container_first_secure">
          <div className="container_image_title">
            <Title
              text="Primer seguro de vida con indexación bursátil."
              className="first_secure_title"
            />
            <img src="/images/heart.png" />
          </div>
          <p>
            Queremos que vivas tranquilo y asegurando lo que más quieres.
            Nuestro seguro de vida y acumulación es el único seguro de vida que
            te protege y además acumulas para tus planes futuros de viajes,
            estudio, tu casa, o incluso, para complementar tu pensión.
          </p>
          <Button
            fontSize="25px"
            fontWeight="100"
            paddingRight="40px"
            paddingLeft="40px"
            onClickEvent={showModalQuote}
          >
            Calcular seguro
          </Button>
        </div>

        <Fade right>
          <KnowMore />
        </Fade>
        <Fade left>
          <Slider />
        </Fade>
        <AccordionSection />
        <Opinions />
        <OurBlog />
      </Layout>
    </div>
  );
};

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  Index
);
