import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Title from './title';
import { AccordionInterface } from '../models/Accordion';
import '../scss/accordion.scss';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const ACCORDIONS: AccordionInterface[] = [
  {
    title: '¿Qué es Proyectat?',
    description:
      'Proyectat es la primera agencia máster de seguros en el país que desde su creación, no ha parado de crecer y revolucionar la forma de disfrutar de los seguros. <br /><br /> Somos una familia de consultores patrimoniales de alto desempeño que sumamos cientos de años de experiencia en proteger la tranquilidad de nuestros clientes. Ofrecemos productos innovadores que mejoran la calidad de vida financiera tanto de nuestros aliados como de nuestros asegurados.',
  },
  {
    title: '¿Qué servicios ofrece Proyectat?',
    description:
      'Proyectat ofrece a sus clientes asesoría financiera de alto nivel, Somos los únicos en el país que hacen parte de MDRT, Million Dollar Round Table que reúne a los mejores profesionales de servicios financieros y de seguros de vida más importantes del mundo. Esta membresía es reconocida internacionalmente como el estándar de excelencia en el negocio de seguros de vida. <br /><br /> Ofrecemos el primer Primer seguro de vida con indexación bursátil del país, lo que le permite a nuestros asegurados estar tranquilos en todos los momentos de su vida mientras acumulan capital para cumplir sus objetivos financieros. Además acompañamos a importantes compañías del país a desarrollar efectivamente sus canales de vida individual.',
  },
  {
    title: '¿Por qué comprar un seguro con Proyectat?',
    description:
      'En Proyectat no sólo estás comprando un seguro, desde que haces parte de nuestra familia, cuentas con el acompañamiento de un consultor patrimonial especializado experto en convertir tus planes financieros en realidad.',
  },
  {
    title: '¿Cuál es la diferencia de Proyectat con las otras agencias?',
    description:
      'Proyectat reúne a los mejores profesionales financieros del sector y los productos más innovadores del mercado. Nuestro objetivo es que estés tranquilo y seguro. Somos expertos en la creación de canales de distribución en vida individual y WSM en Colombia y Latam. Soportados con desarrollo comercial que nos permite manejar la línea completa de creación del equipo para lograr un alto desempeño, desarrollo permanente del talento, crecimiento sostenido y rentabilidad.',
  },
  {
    title: '¿Qué significa seguro de vida con indexación bursátil?',
    description:
      'Cuando adquieres un seguro de vida con indexación bursátil, significa que una parte de la prima que pagas es destinada al rubro de protección que incluyas en tu póliza, la otra parte, se destina al rubro de acumulación, que a partir de determinado tiempo, con base en ese capital, puedes solicitar un valor de rescate, bono de fidelidad y rendimientos indexados al SP 500, uno de los índices bursátiles de mejor rendimiento.',
  },
  {
    title: '¿Qué es el SP500?',
    description:
      'El acrónimo SP500 es un índice bursátil donde se recopilan las 500 empresas más grandes de Estados Unidos. <br /><br /> Este es uno de los índices de renta variable más seguido y es considerado como referente para la economía de los EE.UU. Además es uno de los índices más utilizados por los inversores institucionales, ya que incorpora una de las carteras más grandes de empresas.',
  },
];

export interface AccordionProps {}

const AccordionSection: React.FC<AccordionProps> = props => {
  const [expanded, setExpanded] = React.useState<string | false>();

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="accordion__container">
      <div className="container">
        <Title text="Todo lo que debes saber sobre Proyectat®" className="accordion_title"/>

        {ACCORDIONS.map((ACCORDION, index) => (
          <Accordion
            key={`accordion-${index}`}
            square
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <h3 className="accordion__title">{ACCORDION.title}</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p
                className="accordion__description desktop__justify"
                dangerouslySetInnerHTML={{ __html: ACCORDION.description }}
              ></p>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default AccordionSection;
