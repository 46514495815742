import React from 'react';
import '../scss/slider.scss';
import { connect } from 'react-redux';
import Title from './title';
import Button from './button';
import { handleShowModalQuote } from '../redux/actions';

export interface SliderProps {
  dispatch: any;
}

interface SliderInterface {
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  image: string;
}

const SLIDERS: SliderInterface[] = [
  {
    title: 'Estás 100% protegido',
    description:
      'Protege tu integridad física mientras generas una rentabilidad de las más altas del sector para cumplir un objetivo que tengas a largo plazo.',
    buttonText: 'Cotizar',
    buttonLink: '/about',
    image: '/images/7.png',
  },
  {
    title: 'Te aseguramos casi que para siempre',
    description:
      'Este seguro, como ningún otro, te protege hasta tu edad objetivo elegida o máximo por 100 años.',
    buttonText: 'Cotizar',
    buttonLink: '/about',
    image: '/images/8.png',
  },
  {
    title: 'Un seguro con la mayor rentabilidad',
    description:
      'Es el único seguro de vida con un valor de rescate atado al desempeño del índice de S&P 500* con una tasa de interés mínima garantizada.',
    buttonText: 'Cotizar',
    buttonLink: '/about',
    image: '/images/9.png',
  },
];

const Slider: React.FC<SliderProps> = props => {
  const showModalQuote = () => props.dispatch(handleShowModalQuote(true));
  return (
    <div className="sliders">
      <Title
        text="¿Por qué elegir un seguro de vida y acumulación con Proyectat®?"
        className="sliders_title"
      />
      <section className="slider_container ">
        {SLIDERS.map((SLIDER, index) => (
          <article key={`slider-${index}`}>
            <div className="slider_image">
              <img className="Logo slider" src={SLIDER.image} />
            </div>
            <h3>{SLIDER.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: SLIDER.description }}></p>
            <Button
              fontSize="18px"
              fontWeight="100"
              paddingRight="40px"
              paddingLeft="40px"
              onClickEvent={showModalQuote}
            >
              {SLIDER.buttonText}
            </Button>
          </article>
        ))}
      </section>
    </div>
  );
};

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  Slider
);
