import React from 'react';
import './../scss/our-blog.scss';
import Title from './title';
import { graphql, Link, useStaticQuery } from 'gatsby';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

export interface OurBlogProps {}

const OurBlog: React.FC<OurBlogProps> = () => {
  const { allMarkdownRemark } = useStaticQuery(query);

  return (
    <div>
      {allMarkdownRemark.nodes.length > 0 && (
        <div className="container_our_blog">
          <Title className="our_blog__title" text="Nuestro blog y noticias" />
          <p className="our_blog__p">
            Entérate sobre las últimas noticias sobre negocios y finanzas
          </p>
          <div css={{ '@media (max-width: 767px)': { display: 'none' } }}>
            <div className="our_blog__last_news">
              {allMarkdownRemark.nodes.map((BLOG, index) => (
                <div className="last_new" key={`blog-${index}`}>
                  <div>
                    <div className="last_new__title-container">
                      <label className="last_new__title">
                        {BLOG.frontmatter.title}
                      </label>
                    </div>
                    <img
                      className="last_new__img"
                      src={BLOG.frontmatter.image}
                    />
                    <p className="last_new__description">
                      {BLOG.frontmatter.subtitle}
                    </p>
                  </div>
                  <div>
                    <Link
                      className="last_new__read_more"
                      to={`/blog${BLOG.fields.slug}`}
                    >
                      Leer más...
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            id="blog__sliders"
            css={{ '@media (min-width: 768px)': { display: 'none' } }}
          >
            <AwesomeSlider>
              {allMarkdownRemark.nodes.map((BLOG, index) => (
                <div className="last_new" key={`blog-${index}`}>
                  <label className="last_new__title">
                    {BLOG.frontmatter.title}
                  </label>
                  <img className="last_new__img" src={BLOG.frontmatter.image} />
                  <p className="last_new__description">
                    {BLOG.frontmatter.subtitle}
                  </p>
                  <Link
                    className="last_new__read_more"
                    to={`/blog${BLOG.fields.slug}`}
                  >
                    Leer más...
                  </Link>
                </div>
              ))}
            </AwesomeSlider>
          </div>
        </div>
      )}
    </div>
  );
};

export const query = graphql`
  query Blogs {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      nodes {
        frontmatter {
          title
          subtitle
          image
        }
        fields {
          slug
        }
      }
    }
  }
`;

export default OurBlog;
