import React, { Fragment } from 'react';
import './../scss/opinions.scss';
import Title from './title';

export interface OpinionsProps {}

interface NewspaperInterface {
  description: string;
  author: string;
  url: string;
}

const NEWSPAPERS: NewspaperInterface[] = [
  {
    description: '“La primer agencia máster de Colombia en el Million Dolar Round Table”',
    author: 'author__forbes',
    url: 'https://forbes.co/2020/08/06/economia-y-finanzas/cinco-colombianos-entran-al-club-de-mejores-corredores-de-seguros-en-el-mundo/',
  },
  {
    description: '“Cinco colombianos ingresaron a la Million Dolar Round Table”',
    author: 'author__dinero',
    url: 'https://www.dinero.com/empresas/confidencias-on-line/articulo/cinco-colombianos-ingresaron-a-la-million-dollar-round-table/294902',
  },
  {
    description: '“Proyectat entre el club más selecto de consultores financieros”',
    author: 'author__el_espectador',
    url: 'https://www.elespectador.com/noticias/economia/estos-son-los-cinco-colombianos-que-ingresaron-a-la-mesa-del-millon-de-dolares/',
  },
  {
    description: '“La emisión de primas en julio creció 4,8%”',
    author: 'author__la_nota',
    url: 'https://lanotaeconomica.com.co/finanzas/la-emision-de-primas-en-julio-crecio-48-a-lo-registrado-en-el-mismo-mes-en-2019.html',
  },
  {
    description: '"el sector asegurador es el que más le está apostando a la equidad de género en el contexto laboral"',
    author: 'author__portafolio',
    url: 'https://www.portafolio.co/negocios/empresas/sector-asegurador-el-primero-en-equidad-de-genero-554919',
  },
  {
    description: '"las mujeres no solo ganan más, también tienen mayor crecimiento profesional y flexibilidad para manejar su tiempo"',
    author: 'author__el_tiempo',
    url: 'https://www.eltiempo.com/economia/sector-financiero/asi-es-la-participacion-femenina-en-la-industria-aseguradora-del-pais-611284',
  },
];

const Opinions: React.FC<OpinionsProps> = props => {
  return (
    <Fragment>
      <div className="container_they_say">
        <Title text="Lo que dicen de nosotros..." />
        <p className="Opinions__p">Proyectat en la prensa</p>
        <div className="container_newspaper">
          {NEWSPAPERS.map((NEWSPAPER, index) => (
            <a
              key={`newspaper-${index}`}
              href={NEWSPAPER.url}
              target="_blank"
              className={
                (!!NEWSPAPER.author ? NEWSPAPER.author : '') + ' newspaper'
              }
            >
              <div className="Opinions__img"></div>
              <p className="Opinions__description">{NEWSPAPER.description}</p>
            </a>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default Opinions;
