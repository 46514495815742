import React from 'react';
import { Link } from 'gatsby';
import '../scss/know-more.scss';

export interface KnowMoreProps {}

interface ArticleInterface {
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  image: string;
}

const ARTICLES: ArticleInterface[] = [
  {
    title: 'Consultores patrimoniales expertos',
    description:
      'Nuestros consultores patrimoniales son literalmente los mejores de la región. Somos la única compañía de Colombia con consultores' +
      ' dentro de la Asociación Premier de Profesionales en Finanzas, de la que hacen parte los mejores corredores de seguros y agentes' +
      ' financieros del mundo <br /><br /> Con nosotros, tienes a tu lado un experto para ayudarte a cumplir tus objetivos financieros con las mejores herramientas.',
    buttonText: 'Conoce más sobre nuestro seguro de vida e indexación Bursátil',
    buttonLink: '/vida-y-acumulacion',
    image: '/images/6.png',
  },
  {
    title: 'Estás seguro y respaldado por los mejores aliados',
    description:
      'Nuestro mantra es que estés tranquilo. Todos nuestros productos y servicios son pensados para las personas de hoy, flexibles y personalizados' +
      ' para que estés tranquilo frente a todo lo que más te preocupa. <br /><br /> Somos aliados de las mayores y más sólidas aseguradoras del mundo' +
      ' para traerte los servicios más innovadores y exclusivos.',
    buttonText: 'Conoce más sobre nuestro seguro de vida e indexación Bursátil',
    buttonLink: '/vida-y-acumulacion',
    image: '/images/4.png',
  },
];

const KnowMore: React.FC<KnowMoreProps> = props => {
  return (
    <section className="section_know_more container">
      {ARTICLES.map((ARTICLE, index) => (
        <article key={`article-${index}`}>
          <div className="article_text">
            <h3>{ARTICLE.title}</h3>
            <p
              className="mobile__justify"
              dangerouslySetInnerHTML={{ __html: ARTICLE.description }}
            ></p>
            <Link className="article_link" to={ARTICLE.buttonLink}>
              {ARTICLE.buttonText}
            </Link>
          </div>
          <div className="article_image">
            <img className="Logo articulo" src={ARTICLE.image} />
          </div>
        </article>
      ))}
    </section>
  );
};

export default KnowMore;
